import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ModulecardsData } from '../../utils/constants';
import { Title } from './CardSection';
import { Container } from 'react-bootstrap';
import AnimatedSection from '../../components/AnimatedUp';

// Styled Components for Slider
const SliderWrapper = styled.div`
  padding: 10px 0;

  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .slick-track {
    display: flex;
    gap: 20px; // Adjust gap between slides as needed
  }

  .slick-dots {
    bottom: -5px; // Adjust dots position if needed
    color: #ef5226; // Change the color of the dots
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #ef5226; // Change the color of the dot icons
  }

  // Hide dots on mobile devices
  @media (max-width: 992px) {
    .slick-dots {
      display: none !important;
    }
  }
`;


const Card = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 420px; // Fixed height for consistency
  min-height: 330px; // Ensure minimum height
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Space between image and text
  cursor: pointer; // Change cursor to pointer on hover

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1200px) {
    width: 45vw;
    height: auto; // Use auto height for responsive scaling
  }

  @media (max-width: 992px) {
    width: 40vw;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 80vw; // Cards become wider on smaller screens
    height: auto;
  }

  @media (max-width: 576px) {
    width: 40vh !important;
    height: auto;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 250px; // Fixed height for the image
  object-fit: cover; // Maintain aspect ratio while covering the area
  border-radius: 8px;

  @media (max-width: 992px) {
    height: 200px; // Adjust height for smaller screens
  }

  @media (max-width: 768px) {
    height: 180px; // Adjust height for even smaller screens
  }

  @media (max-width: 576px) {
    height: 150px; // Adjust height for mobile devices
  }
`;

const CardTitle = styled.h5`
  margin: 15px 0;
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 992px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 576px) {
    font-size: 20px; // Smaller font on mobile devices
  }
`;

const CardText = styled.p`
  font-size: 13px;
  color: #666;

  @media (max-width: 992px) {
    font-size: 13px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 576px) {
    font-size: 11px; // Smaller text for small devices
  }
`;

const SubTitle = styled.h6`
  display: block;
  margin: auto; // Optional: Add some margin for spacing
  text-align: center; // Center the text
  @media (max-width: 992px) {
    font-size: 1rem; 
    width:100% !important;
    padding: 1rem; 
  }
`;

const ImportantModuleSection = () => {
  const [pauseSlider, setPauseSlider] = useState(false);

  // Adjust the slider settings to show 4 slides per set and paginate them
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4, // Show 4 slides at once
    slidesToScroll: 4, // Scroll 4 slides at once
    autoplay: true,
    autoplaySpeed: 2000, // Time in milliseconds between slides
    pauseOnHover: pauseSlider, // Pause on hover based on state
    responsive: [
      {
        breakpoint: 1200, // For screens below 1200px
        settings: {
          slidesToShow: 3, // Show 3 slides below 1200px
          slidesToScroll: 3, // Scroll 3 slides at once
        },
      },
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 3, // Show 3 slides below 1024px
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992, // Smaller tablets
        settings: {
          slidesToShow: 2, // Show 2 slides below 992px
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 1, // Show 1 slide below 768px
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <AnimatedSection id="hero" className="animated-section">
      <div>
        <Title className="text-center pt-4 pt-md-0">Important Modules</Title>
        {/* <SubTitle className="mb-3 mt-3 w-50 mx-auto">
        Transform your business with Tech Cloud ERP, CRM, Sales, Purchase, Inventory, Production, Job Work, QualityTransform your business with Tech Cloud ERP CRM, Sales, Purchase, Inventory, Production, Job Work, Quality Control, Quality Assurance, HRM, Finance, Imports & Exports, Project, Design, POS, and many more., Assets, HRM, Finance, Imports & Exports, Project, Design and POS. 
              </SubTitle> */}
        <Container className="mb-3">
          <SliderWrapper
            onMouseEnter={() => setPauseSlider(true)} 
            onMouseLeave={() => setPauseSlider(false)} 
          >
            <Slider {...sliderSettings}>
              {ModulecardsData.map((card, index) => (
                <Card key={card.id} className="mb-5">
                  <CardImage src={card.image} alt={card.alt} />
                  <CardTitle>{card.title}</CardTitle>
                  <CardText>{card.description}</CardText>
                </Card>
              ))}
            </Slider>
          </SliderWrapper>
        </Container>
      </div>
    </AnimatedSection>
  );
};

export default ImportantModuleSection;
